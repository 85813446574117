import axios from "axios";
import { toast } from "react-toastify";
import { clearCacheAndReload, clearLocalStorage } from "src/utils/clearCache";
import { groupMessagesByTimestamp } from "src/utils/formatTime";
import { v4 as uuidv4 } from "uuid";
import { formatTitle } from "../../utils/formatTitle";
import { Authentication } from "./authentication";
import { mapperMessages, sortByDate } from "./mappers";

const api = axios.create({ baseURL: process.env.REACT_APP_ENDPOINT });
const auth = new Authentication(api);

const fiveMinutesInMilliseconds = 300000;
const thirtySecondsInMilliseconds = 30000;

let ws;

const DEFAULT_STATE = {
  messages: [],
  suggestions: [],
  isLoading: true,
  initialMessage: false,
  notAuthorized: false,
  groupMessages: {},
  isSystemOffline: false,
};

export const ACTIONS = {
  GET_MESSAGES: "GET",
  SEND_MESSAGE: "SEND",
  RECEIVE_MESSAGE: "RECEIVE",
  UPDATE_MESSAGE: "UPDATE",
  SET_PID: "SETPID",
  NOT_AUTHORIZED: "NOTAUTHORIZED",
  AUTHORIZED: "AUTHORIZED",
  REMOVE: "REMOVE",
  UPDATE_MESSAGE_CHOICE: "UPDATE_MESSAGE_CHOICE",
  LOADING: "LOADING",
  NOT_LOADING: "NOT_LOADING",
  SYSTEM_OFFLINE: "SYSTEM_OFFLINE",
  SYSTEM_ONLINE: "SYSTEM_ONLINE",
};

export const STATUS = {
  SENDING: "SENDING",
  SENT: "SENT",
  RECEIVED: "RECEIVED",
  ERROR: "ERROR",
};

export const CONTENTTYPE = {
  TEXT: "text",
  IMAGE: "image",
  AUDIO: "audio",
  LIST: "list",
  LOCATION: "location",
  FILE: "document",
  CAROUSEL: "carousel",
  CAROUSEL_CHOICE: "carousel_choice",
  INVISIBLE: "invisible",
  IMAGE_LOCATION: "image_location",
  PAYLOAD: "payload",
  VIDEO: "video",
  CHAT_DATA: "chat_data",
  CHAT_START: "chat_start",
  CHAT_END: "chat_end",
};

export const FILETYPE = {
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  DOC: "doc",
};

export const MESSAGETYPE = {
  INCOMING: "incoming",
  OUTGOING: "outgoing",
};

export const SET_USER = `#set-user;`;

//Base functions
export const appReducer = (state = DEFAULT_STATE, action) => {
  const messages = [...state.messages];
  const groupMessages = { ...state.groupMessages };

  let groups = {};

  switch (action.type) {
    case ACTIONS.GET_MESSAGES:
      const newMessages = action.messages.filter((newMsg) => {
        return !messages.some((oldMsg) => (
          oldMsg.text === newMsg.text &&
          oldMsg.timestamp === newMsg.timestamp &&
          oldMsg.contentType === newMsg.contentType &&
          oldMsg.type === newMsg.type
        ))
      })

      const allMessages = [...messages, ...newMessages];

      groups = groupMessagesByTimestamp(allMessages);

      return {
        ...state,
        messages: allMessages,
        groupMessages: sortByDate(groups),
        suggestions: [],
        isLoading: false,
        initialMessage: true,
      };

    case ACTIONS.SEND_MESSAGE:
      messages.push(action.message);
      groups = groupMessagesByTimestamp(messages);

      return {
        ...state,
        messages,
        groupMessages: { ...groupMessages, ...groups },
        suggestions: [],
        isLoading: false,
      };

    case ACTIONS.UPDATE_MESSAGE_CHOICE:
      return {
        ...state,
        suggestions: action.messages[0].suggestions || [],
      };

    case ACTIONS.UPDATE_MESSAGE:
      const updatedMsgs = messages.map((msg) =>
        msg._id === action._id
          ? {
              ...msg,
              id: action._id,
              contentType: action.contentType || msg.contentType,
              status: action.status,
              text: action.text || msg.text,
            }
          : msg
      );

      localStorage.setItem("msg_history", JSON.stringify(updatedMsgs));
      groups = groupMessagesByTimestamp(updatedMsgs);

      return {
        ...state,
        messages: updatedMsgs,
        groupMessages: { ...groupMessages, ...groups },
        isLoading: false,
      };

    case ACTIONS.REMOVE:
      const afterUpdatedMsgs = messages.filter((msg) => msg._id !== action._id);
      localStorage.setItem("msg_history", JSON.stringify(afterUpdatedMsgs));
      groups = groupMessagesByTimestamp(afterUpdatedMsgs);

      return {
        ...state,
        messages: afterUpdatedMsgs,
        groupMessages: { ...groups },
        isLoading: false,
      };

    case ACTIONS.RECEIVE_MESSAGE:
      action.messages.forEach((m) => {
        if (m.msg.type !== "chat_start" && m.msg.type !== "chat_end") {
          messages.push({
            _id: uuidv4(),
            text: m.msg.text,
            status: STATUS.RECEIVED,
            contentType: m.msg.type,
            type: MESSAGETYPE.INCOMING,
            timestamp: new Date().getTime(),
          });
        }
        
        if (m.msg.type === "chat_start") salesForceChatOpenend();
        
        if (m.msg.type === "chat_end") salesForceChatClosed();
      });

      localStorage.setItem("msg_history", JSON.stringify(messages));
      groups = groupMessagesByTimestamp(messages);

      return {
        ...state,
        messages,
        suggestions: action.messages[0].suggestions || [],
        groupMessages: { ...groupMessages, ...groups },
        isLoading: false,
      };

    case ACTIONS.NOT_AUTHORIZED:
      return {
        ...state,
        notAuthorized: true,
      };

    case ACTIONS.AUTHORIZED:
      return {
        ...state,
        notAuthorized: false,
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case ACTIONS.NOT_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case ACTIONS.SYSTEM_OFFLINE:
      localStorage.setItem("systemOffline", true);
      return {
        ...state,
        isSystemOffline: true,
      };

    case ACTIONS.SYSTEM_ONLINE:
      localStorage.setItem("systemOffline", false);
      return {
        ...state,
        isSystemOffline: false,
      };

    default:
      return state;
  }
};

function startPingPong() {
  if (pingPongInterval) clearInterval(pingPongInterval);
  pingPongInterval = setInterval(() => {
    ws.send(
      JSON.stringify({
        action: "PING",
      })
    );
  }, 30000);
}

const fetchMessages = () => {
  return api.get("/fetch", {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const sendNotifyWebhook = (data) => {
  return api.post(
    `https://api-${process.env.REACT_APP_BOT}.fintalk.io/dev/notifywebhook`,
    data,
    {}
  );
};

let attempts = 0;
const getWS = (dispatch, recreate = false) => {
  if (localStorage.getItem("systemOffline") === "true") return;
  return new Promise((res, rej) => {
    if (ws && ws.readyState === 1 && recreate) {
      ws.close();
    }

    if (!ws || ws.readyState !== 1 || recreate) {
      ws = new WebSocket(
        `${process.env.REACT_APP_WSS}?Auth=${localStorage.getItem("token")}`
      );

      ws.onopen = async () => {
        attempts = 0;
        res(ws);

        await dispatch(updateMessages())
      };

      ws.onerror = async (_e) => {
        console.log('onError', _e);

        try {
          if (attempts === 5) {
            dispatch({
              type: ACTIONS.NOT_AUTHORIZED,
            });
            return rej("Erro conectar ao socket.");
          }

          attempts === 1 && toast.warning("Tentando reconectar ao socket.");
          attempts++;

          await auth.authorize(dispatch);
          await getWS(dispatch);
          res(ws);
        } catch (error) {
          rej("Erro conectar ao socket.");
        }
      };

      ws.onclose = (e) => {
        console.log("Closed!", e);
      };

      ws.onmessage = (m) => {
        receiveMessage(JSON.parse(m.data), dispatch);
      };
    } else {
      res(ws);
    }
  });
};

//Method functions
export function getMessages(renew = false) {
  if (localStorage.getItem("systemOffline") !== "true");
  return async (dispatch) => {
    try {
      await auth.authorize(dispatch, renew);
      await getWS(dispatch, true);

      const remoteMessages = await fetchMessages();
      const newRemoteMessages = remoteMessages.data.msgs.filter(
        (msg) =>
          msg.contentType !== "chat_start" && msg.contentType !== "chat_end"
      );
      newRemoteMessages.forEach(mapperMessages);

      dispatch({
        type: ACTIONS.GET_MESSAGES,
        messages: newRemoteMessages,
      });
    } catch (error) {
      if (!localStorage.getItem("removeToken") === "true") {
        toast.error(
          typeof error === "string" ? error : "Erro ao buscar histórico"
        );
      }

      localStorage.removeItem("removeToken");

      dispatch({
        type: ACTIONS.GET_MESSAGES,
        messages: [],
      });
    }
  };
}

export function updateMessages() {
  return async (dispatch) => {
    try {
      await auth.authorize(dispatch, false);

      const remoteMessages = await fetchMessages();
      remoteMessages.data.msgs.forEach(mapperMessages);

      dispatch({
        type: ACTIONS.GET_MESSAGES,
        messages: remoteMessages.data.msgs,
      });
    } catch {
      console.log("Erro ao buscar mensagens");
    }
  };
}

export function sendMessage(text, type = CONTENTTYPE.TEXT, callback) {
  callback = typeof callback === "function" ? callback : (r) => {};

  if (text === "/resetCache") {
    clearCacheAndReload();
    return;
  }

  if (text === "/resetStorage") {
    clearLocalStorage();
    return;
  }

  text = text.replace(/^['`*]/g, "");
  const messageId = uuidv4();

  return (dispatch) => {
    try {
      dispatch({
        type: ACTIONS.SEND_MESSAGE,
        message: {
          _id: messageId,
          text,
          status: STATUS.SENDING,
          contentType: type,
          type: MESSAGETYPE.OUTGOING,
          timestamp: new Date().getTime(),
        },
      });

      getWS(dispatch)
        .then((w) => {
          w.send(
            JSON.stringify({
              action: "MESSAGE",
              text,
            })
          );

          dispatch([
            {
              type: ACTIONS.UPDATE_MESSAGE,
              _id: messageId,
              status: STATUS.RECEIVED,
            },
          ]);
          // clearTimeout(timeBotInterval);
          // startInterval(w);
          callback(text);
        })
        .catch((e) => {
          callback(false);
        });
    } catch (e) {
      callback(false);
    }
  };
}

const receiveMessage = (msg, dispatch) => {
  switch (msg.action) {
    case "SENT":
      break;
    case "PING":
    case "PONG":
      startPingPong();
      break;
    case "MESSAGE":
      dispatch([
        {
          type: ACTIONS.RECEIVE_MESSAGE,
          messages: [
            {
              msg: msg.msg,
              suggestions: msg.suggestions,
            },
          ],
        },
      ]);

      if (!isSalesforceChatOpen()) {
        clearTimeout(timeBotInterval);
        clearInterval(salesforceMessagesInterval);
        startInterval(ws);
        return;
      }

      startSalesforceMessagesTimeout();

      const acknowledged = {
        action: "ACKNOWLEDGED",
        text: msg.msg.text,
        contentType: "text",
      };

      ws.send(JSON.stringify(acknowledged));
      break;
    default:
      break;
  }
};

export function sendRegisterDevice(deviceToken) {
  return (dispatch) => {
    getWS(dispatch).then((w) => {
      w.send(
        JSON.stringify({
          action: "REGISTERDEVICE",
          token: deviceToken,
        })
      );
    });
  };
}

export function sendLocation(location) {
  const messageId = uuidv4();
  const locationFormated = `${location.coords.longitude}|${location.coords.latitude}`;
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SEND_MESSAGE,
      message: {
        _id: messageId,
        text: locationFormated,
        status: STATUS.SENDING,
        contentType: CONTENTTYPE.LOCATION,
        type: MESSAGETYPE.OUTGOING,
        timestamp: new Date().getTime(),
      },
    });

    getWS(dispatch).then((w) => {
      w.send(
        JSON.stringify({
          action: "MESSAGE",
          text: locationFormated,
        })
      );
      dispatch([
        {
          type: ACTIONS.UPDATE_MESSAGE,
          _id: messageId,
          status: STATUS.RECEIVED,
        },
      ]);
    });
  };
}

export function sendCarouselChoice(items) {
  const messageId = uuidv4();
  const text = items.map((item) => formatTitle(item.title)).toString();

  return (dispatch) => {
    try {
      dispatch({
        type: ACTIONS.SEND_MESSAGE,
        message: {
          _id: messageId,
          text,
          status: STATUS.SENDING,
          details: items,
          contentType: CONTENTTYPE.CAROUSEL_CHOICE,
          type: MESSAGETYPE.OUTGOING,
          timestamp: new Date().getTime(),
        },
      });

      getWS(dispatch).then((w) => {
        w.send(
          JSON.stringify({
            action: "MESSAGE",
            text,
          })
        );
        dispatch([
          {
            type: ACTIONS.UPDATE_MESSAGE,
            _id: messageId,
            contentType: CONTENTTYPE.CAROUSEL_CHOICE,
            status: STATUS.RECEIVED,
            details: items,
          },
        ]);
      });
    } catch (e) {
      console.log("sendMessage>catch", e);
    }
  };
}

export function sendFile(file, fileType, location, callback) {
  callback = typeof callback === "function" ? callback : (r) => {};

  const locationImageVerify =
    !!location &&
    fileType === FILETYPE.IMAGE &&
    process.env.REACT_APP_LOCATION_IMAGE;
  const messageId = uuidv4();

  let contentFile = contentFileType(fileType);
  let locationFormated = "";

  if (locationImageVerify) {
    locationFormated = `${location.coords.longitude}|${location.coords.latitude}`;
    contentFile = CONTENTTYPE.IMAGE_LOCATION;
  }

  const fileExtensionRegex = /\.([0-9a-z]+)(?:[?#]|$)/i;
  const extension = file.name.match(fileExtensionRegex)[1] || null;

  return (dispatch) => {
    dispatch({
      type: ACTIONS.SEND_MESSAGE,
      message: {
        _id: messageId,
        text: titleFileType(fileType),
        status: STATUS.SENDING,
        contentType: contentFile,
        type: MESSAGETYPE.OUTGOING,
        timestamp: new Date().getTime(),
      },
    });

    auth.authorize(dispatch).then((t) => {
      api
        .post(
          "upload",
          { filename: file.name, mimeType: `${contentFile}/${extension}` },
          {
            headers: {
              Authorization: t,
            },
          }
        )
        .then(async (r) => {
          const jsonResponse = r.data;
          const { url, preSignedUrl } = jsonResponse;

          await fetch(preSignedUrl, {
            method: "put",
            body: file,
            headers: {
              "Content-Length": file.length,
              "Content-Type": `${contentFile}/${extension}`,
            },
          });

          getWS(dispatch)
            .then((w) => {
              let msgFile = {
                action: "MESSAGE",
                text: url,
                contentType: contentFile,
              };

              if (locationImageVerify) {
                msgFile.text = JSON.stringify({
                  url: url,
                  location: locationFormated,
                });
              }

              w.send(JSON.stringify(msgFile));

              dispatch([
                {
                  type: ACTIONS.UPDATE_MESSAGE,
                  _id: messageId,
                  status: STATUS.RECEIVED,
                  contentType: contentFileType(fileType),
                  text: url,
                },
              ]);

              if (
                contentFileType(fileType) === CONTENTTYPE.VIDEO ||
                contentFileType(fileType) === CONTENTTYPE.AUDIO
              ) {
                dispatch({
                  type: ACTIONS.UPDATE_MESSAGE,
                  message: {
                    _id: messageId,
                    text: url,
                  },
                });
              }

              callback(url);
            })
            .catch((e) => {
              toast.error("getWS>catch");
              callback(null);
            });
        })
        .catch((e) => {
          const errorMessage =
            "Tamanho do arquivo maior que o permitido ou formato inválido";
          toast.error(errorMessage);
          dispatch([
            {
              type: ACTIONS.REMOVE,
              _id: messageId,
            },
          ]);

          callback(null);
        });
    });
  };
}

let timeBotInterval;

export function startInterval(w = ws) {
  if (w.readyState === 1) {
    timeBotInterval = setTimeout(() => {
      if (!isSalesforceChatOpen()) {
        w.send(
          JSON.stringify({
            action: "MESSAGE",
            text: "#timeBot",
          })
        );
        w.close();
      }
    }, fiveMinutesInMilliseconds);
  }
}

export function clearConversationTimeout() {
  if (timeBotInterval) {
    clearTimeout(timeBotInterval);
    timeBotInterval = null;
  }
}

let pingPongInterval;
let salesforceMessagesInterval;

export function startSalesforceMessagesTimeout() {
  if (salesforceMessagesInterval) clearInterval(salesforceMessagesInterval);

  salesforceMessagesInterval = setInterval(() => {
    if (isSalesforceChatOpen()) updateMessages();
  }, thirtySecondsInMilliseconds);
}

export function salesForceChatOpenend() {
  clearConversationTimeout();
  startSalesforceMessagesTimeout();
  startPingPong();
  sessionStorage.setItem("@falazap:salesForceChatOpen", "true");
}

export function salesForceChatClosed() {
  clearConversationTimeout();
  startSalesforceMessagesTimeout();

  startInterval();
  sessionStorage.setItem("@falazap:salesForceChatOpen", "false");

  clearTimeout(pingPongInterval);
  clearInterval(salesforceMessagesInterval);
}

function isSalesforceChatOpen() {
  const salesforceChatOpened = sessionStorage.getItem(
    "@falazap:salesForceChatOpen"
  );

  return salesforceChatOpened === "true";
}

timeBotInterval = setTimeout(() => {
  if (!isSalesforceChatOpen()) {
    ws.send(
      JSON.stringify({
        action: "MESSAGE",
        text: "#timeBot",
      })
    );
  }

  if (ws.readyState === 1) ws.close();
}, fiveMinutesInMilliseconds);

//Auxiliary functions
function titleFileType(fileType) {
  switch (fileType) {
    case FILETYPE.IMAGE:
      return "Enviando imagem...";
    case FILETYPE.AUDIO:
      return "Enviando áudio...";
    case FILETYPE.DOC:
      return "Enviando arquivo...";
    default:
      return "Enviando...";
  }
}

function contentFileType(fileType) {
  switch (fileType) {
    case FILETYPE.IMAGE:
      return CONTENTTYPE.IMAGE;
    case FILETYPE.AUDIO:
      return CONTENTTYPE.AUDIO;
    case FILETYPE.DOC:
      return CONTENTTYPE.FILE;
    case FILETYPE.VIDEO:
      return CONTENTTYPE.VIDEO;
    default:
      return CONTENTTYPE.TEXT;
  }
}
