import React, { createContext, useState } from "react";
import "../../components/Header/Header.scss";
import "./styles.scss";

import defineTheme from "../../themes/getTheme";

type RequestContextPayload = {
  isFetch: boolean;
  setIsFetch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RequestContext = createContext<RequestContextPayload | null>(null);

interface RequestProviderProps {
  children: JSX.Element;
}

export function RequestProvider({ children }: RequestProviderProps) {
  const [isFetch, setIsFetch] = useState(false);
  const isPrivate = init();
  const currentTheme = defineTheme();

  function init() {
    try {
      return typeof window.localStorage === "undefined";
    } catch {
      return true;
    }
  }

  return (
    <RequestContext.Provider
      value={{
        isFetch,
        setIsFetch,
      }}
    >
      {isPrivate ? (
        <>
          <div className="header-bar">
            <div className="header-photo">
              <img src={currentTheme.url} alt={currentTheme.name} />
            </div>
            <div className="header-name">
              <span>{currentTheme.name}</span>
            </div>
          </div>
          <div className="main-div">
            <p>
              Ops! Parece que você está tentando acessar o chat por uma guia
              anônima e algumas permissões foram bloqueadas. Acesse por uma guia
              convencional, ou desbloqueie as permissões.
              <br />
              <br />
              Veja como alterar as essas configurações{" "}
              <a
                href="https://support.google.com/chrome/answer/114836?hl=pt-BR&ref_topic=9845306"
                target="_blank"
                rel="noopener noreferrer"
              >
                aqui
              </a>
            </p>
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </RequestContext.Provider>
  );
}